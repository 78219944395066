import './App.css';

function App() {
  return (
    <div className="App">
      <div className="center">
        <h1>Site under construction</h1>
        <h2>Come back later in 2025/2026</h2>
      </div>
      <footer>
        <span><a href='mailTo:contact@devestry.com'>contact@devestry.com</a></span>
        <a href="https://buymeacoffee.com/locz3k" >buy me a coffee!</a>
        <span>Devestry 2024 &copy; all rights reserved.</span>
      </footer>
    </div>
  );
}

export default App;
